import './App.css';
import HomePage from './components/HomePage';
import Me from './components/Me.js'

function App() {
  return (
    <div className="App">
        <HomePage></HomePage>
    </div>
  );
}

export default App;
